import React from 'react'
import lifecycle from 'react-pure-lifecycle'

import { StripeProvider, Elements } from 'react-stripe-elements';

import InjectedCheckoutForm from './CheckoutFormElements';

import {
  validateAndPostSubscriptionSCA,
  validateFormOnly,
  toggleCouponField,
  validateCoupon,
  validatePaymentSCA,
  setStripe,
} from '../client/subscribe/actions'

import WelcomeModalSCA from './WelcomeModalSCA'
import ErrorModal from './ErrorModal'

class MyStoreCheckout extends React.Component {
  render() {
    return (
      <Elements>
        <InjectedCheckoutForm {...this.props} />
      </Elements>
    );
  }
}

const makePeriodSwitchHandler = (period) => (event) => {
  event.preventDefault()
  store.dispatch({ type: period })
  return false
}

const submitForm = (field) => (event) => {
  //  console.log("props", this.props);
  event.preventDefault()

  const affiliate = document.URL.split('?id=')[1]

  if (typeof clicky !== 'undefined') {
    clicky.goal('paidInviteSignup')
  }

  // BUG: This should only fire on postSubscription success not validate.
  if (typeof ga !== 'undefined') {
    ga('send', {
      hitType: 'event',
      eventCategory: 'invitepage',
      eventAction: 'paidInviteSignup',
    })
  }

  const form = event.target.form

  const validateForm = field ? validateFormOnly : validateAndPostSubscriptionSCA

  store.dispatch(validateForm({
    email: form.email.value,
    // cardNumber: form.cardNumber.value,
    // date: form.date.value,
    // cvc: form.cvc.value,
    affiliate,
  }, field))

  return false
}

const componentDidMount = (props) => {
  if (window.stripe) {
    console.log('setStripe', window.stripe);

    store.dispatch(setStripe(window.stripe));
  } else {
    console.log('setStripe', null);

    // set to null for SSR
    store.dispatch(setStripe(null));
  }
  // console.log("new state",store.getState());

  if (props.coupon) {
    store.dispatch(toggleCouponField(true))
    store.dispatch(validateCoupon(props.coupon))
  }

  console.log('props mounted', props);
  if (props.subscribing && props.session_id) {
    console.log('validate payment for session id', props.session_id);
    store.dispatch(validatePaymentSCA({ session_id: props.session_id }));
  }
}

const methods = {
  componentDidMount,
}

const formatDiscount = (
  couponValid,
  couponAmountOff,
  couponPercentOff,
  couponDurationInMonths,
  couponDuration,
) => {
  const quantity = couponAmountOff
    ? `$${couponAmountOff / 100}  off `
    : `${couponPercentOff}% off `

  const duration = (couponDuration === 'forever' || couponDuration === 'once')
    ? couponDuration
    : `for ${couponDurationInMonths} months`

  if (couponValid) {
    return quantity + duration
  }

  return 'automatic recurring payments'
}

const StripeFormElements = ({
  styles,
  url,
  price,
  priceYearly,
  yearlySelected,
  currentPrice,
  errors,
  period,
  trialPeriod,
  openModal,
  modalStyles,
  channel,
  group,
  subscribing,
  couponValidating,
  buttonColor,
  textColor,
  backgroundColor,
  status,
  alternatePayment,
  convertCode,
  couponsEnabled,
  couponFieldVisible,
  couponValid,
  couponId,
  couponAmountOff,
  couponPercentOff,
  couponDurationInMonths,
  couponDuration,
  couponSubmitted,
  email,
  coupon,
  stripeKey,
  stripeKeySCA,
  stripeConnectId,
  stripe,

}) => {
  //  console.log("STRIPEFORMELEENTS", this);
  const selected = [styles.planSelectButton, styles.buttonActive]
  const unselected = [styles.planSelectButton, styles.buttonInactive]
  const inputStyles = status === 'Disabled' ? styles.disabledFormInput : styles.formInput
  const payBtnStyles = status === 'Disabled' ? styles.disabledPayBtn : styles.payBtn

  const couponInfo = formatDiscount(
    couponValid,
    couponAmountOff,
    couponPercentOff,
    couponDurationInMonths,
    couponDuration,
  )

  const monthlyStyles = {
    borderColor: buttonColor || '#469CCE',
    color: '#FFF',
    backgroundColor: period === 'monthly' ? buttonColor || '#469CCE' : backgroundColor || '#4D4C69',
  }

  const yearlyStyles = {
    borderColor: buttonColor || '#469CCE',
    color: '#FFF',
    backgroundColor: period === 'yearly' ? buttonColor || '#469CCE' : backgroundColor || '#4D4C69',
  }

  trialPeriod = parseInt(trialPeriod, 10)

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  })

  // Format purchase button text
  let btnContent = ''
  if (trialPeriod) {
    btnContent = 'Start Your Trial'
  } else if (couponValid && couponSubmitted) {
    const priceNum = Number(price.replace(/[^0-9.]+/g, ''))
    btnContent = couponAmountOff
      ? priceNum - (couponAmountOff / 100)
      : priceNum - (priceNum * (couponPercentOff / 100))
    if (btnContent < 0) btnContent = 0
    btnContent = `Pay ${formatter.format(btnContent)} USD`
  } else {
    btnContent = `Pay ${currentPrice} USD`
  }

  errors = errors || []

  const formErrors = errors.reduce((acc, error) => {
    acc[error.field] = styles.fieldError
    return acc
  }, {})
  return (
    <div>
      { (openModal && (openModal.modalType === 'success'))
        && <WelcomeModalSCA
          styles={modalStyles}
          group={group}
          channel={channel}
          user={openModal.user}
          subscription={openModal.subscription}
        />
      }
      { (openModal && (openModal.modalType === 'error'))
        && <ErrorModal
          styles={modalStyles}
          group={group}
          channel={channel}
          error={errors}
        />
      }
      { (trialPeriod > 0)
        && <div className={styles.trialDetail} style={{ color: textColor || '#fff' }}>
          {`${trialPeriod} day free trial`}
        </div>
      }
      {alternatePayment
        && <p className={styles.paymentSelectorGroup}><span style={monthlyStyles} className={styles.paymentSelector} onClick={makePeriodSwitchHandler('SELECT_MONTHLY')}>Monthly</span><span style={yearlyStyles} className={styles.paymentSelector} onClick={makePeriodSwitchHandler('SELECT_YEARLY')}>Yearly</span></p>
      }

      <StripeProvider stripe={stripe}>
        <MyStoreCheckout
          styles={styles}
          url={url}
          price={price}
          priceYearly={priceYearly}
          yearlySelected={yearlySelected}
          currentPrice={currentPrice}
          errors={errors}
          period={period}
          trialPeriod={trialPeriod}
          openModal={openModal}
          modalStyles={modalStyles}
          channel={channel}
          group={group}
          subscribing={subscribing}
          couponValidating={couponValidating}
          buttonColor={buttonColor}
          textColor={textColor}
          backgroundColor={backgroundColor}
          status={status}
          alternatePayment={alternatePayment}
          convertCode={convertCode}
          couponsEnabled={couponsEnabled}
          couponFieldVisible={couponFieldVisible}
          couponValid={couponValid}
          couponId={couponId}
          couponAmountOff={couponAmountOff}
          couponPercentOff={couponPercentOff}
          couponDurationInMonths={couponDurationInMonths}
          couponDuration={couponDuration}
          couponSubmitted={couponSubmitted}
          email={email}
          coupon={coupon}
          submitForm={submitForm}
          formErrors={formErrors}
          inputStyles={inputStyles}
          couponInfo={couponInfo}
          btnContent={btnContent}
          payBtnStyles={payBtnStyles}
        />
      </StripeProvider>

    </div>
  )
}

export default lifecycle(methods)(StripeFormElements)
