// CheckoutForm.js
import React from 'react';
import { injectStripe } from 'react-stripe-elements';
import CardSection from './CardSection';
import Spinner from '../clientAdmin/components/Spinner'

import {
  validateAndPostSubscriptionElements,
  validateAndPostSubscriptionSCA,
  validateFormOnly,
  toggleCouponField,
  validateCoupon,
  validatePaymentSCA,
  setStripe,
} from '../client/subscribe/actions'

class CheckoutForm extends React.Component {
// NOTE: In future used controlled compoenent, DOM access is antipattern
 handleCouponClick = (event) => {
   console.log('handleCouponClick');
   event.preventDefault()
   const coupon = document.getElementById('couponField').value
   console.log('found coupon', coupon);
   store.dispatch(validateCoupon(coupon))
 }

displayCouponField = () => {
  console.log('displayCouponField');
  store.dispatch(toggleCouponField(true))
}

 handleKeyPress = (event) => {
   if (event.key == 'Enter') {
     const coupon = event.target.value
     store.dispatch(validateCoupon(coupon))
     event.preventDefault()
   }

   return false
 }

  handleCardBlur = (ev) => {
    // console.log("handle Blur");
    const cardElement = this.props.elements.getElement('card');
    // console.log("cardElement", cardElement);
  }

  handleSubmit = (event) => {
    event.preventDefault()

    const affiliate = document.URL.split('?id=')[1]

    if (typeof clicky !== 'undefined') {
      clicky.goal('paidInviteSignup')
    }

    if (typeof ga !== 'undefined') {
      ga('send', {
        hitType: 'event',
        eventCategory: 'invitepage',
        eventAction: 'paidInviteSignup',
      })
    }

    store.dispatch(validateAndPostSubscriptionElements({
      email: event.target.email.value,
      emailConfirm: event.target.emailConfirm.value,
      card: this.props.elements.getElement('card'),
      affiliate,
    }, null))
  };

  render() {
    return (
      <div>
      <form
        onSubmit={this.handleSubmit}
        className={this.props.styles.signupForm}
      >
        <input
          disabled={this.props.status === 'Disabled' ? 'disabled' : false}
          className={[(this.props.formErrors.email || ''), this.props.inputStyles, this.props.styles.email].join(' ')}
          name="email"
          defaultValue={this.props.email}
          placeholder="Email"
        />
        <input
          disabled={this.props.status === 'Disabled' ? 'disabled' : false}
          className={[(this.props.formErrors.emailConfirm || ''), this.props.inputStyles, this.props.styles.email, this.props.styles.emailConfirm].join(' ')}
          name="emailConfirm"
          defaultValue={this.props.email}
          placeholder="Confirm email"
          style={{ marginTop: 5 }}
        />

        <CardSection status={this.props.status} onBlur={this.handleCardBlur} styles={this.props.styles} />

        <p>{this.props.path}</p>
        { this.props.period === 'monthly'
          && <div className={this.props.styles.priceDetail} style={{ color: this.props.textColor }}>
            {`${this.props.currentPrice} USD per month (${this.props.couponInfo})`}
          </div>
        }
        { this.props.period === 'yearly'
          && <div className={this.props.styles.priceDetail} style={{ color: this.props.textColor }}>
            {`${this.props.currentPrice} USD per year (${this.props.couponInfo})`}
          </div>
        }
        { this.props.period === 'semiannual'
          && <div className={this.props.styles.priceDetail} style={{ color: this.props.textColor }}>
            {`${this.props.currentPrice} USD semiannually (6-months) (${this.props.couponInfo})`}
          </div>
        }
        { this.props.period === 'quarterly'
          && <div className={this.props.styles.priceDetail} style={{ color: this.props.textColor }}>
            {`${this.props.currentPrice} USD quarterly (3-months) (${this.props.couponInfo})`}
          </div>
        }
        { this.props.period === 'weekly'
          && <div className={this.props.styles.priceDetail} style={{ color: this.props.textColor }}>
            {`${this.props.currentPrice} USD per week (${this.props.couponInfo})`}
          </div>
        }
        { this.props.period === 'daily'
          && <div className={this.props.styles.priceDetail} style={{ color: this.props.textColor }}>
            {`${this.props.currentPrice} USD per day (${this.props.couponInfo})`}
          </div>
        }
        {!this.props.subscribing
          && <button
            type="submit"
            disabled={this.props.status === 'Disabled' ? 'disabled' : false}
            className={this.props.payBtnStyles}
            style={{ background: this.props.buttonColor || '#469CCE', color: '#FFF' }}
            >
            {this.props.btnContent}
          </button>
        }
        </form>
        { this.props.subscribing
          && <div>
            <Spinner color={'#FFFFFF'} size={40} />
          </div>
        }
        { (this.props.period !== 'one-time' && !this.props.couponsEnabled)
          && <div className={this.props.styles.unsubscribeText} style={{ color: this.props.textColor }}>
            No risk, cancel anytime
          </div>
        }
        { (this.props.couponsEnabled && !this.props.couponFieldVisible)
          && <div className={this.props.styles.unsubscribeText} style={{ color: this.props.textColor }}>
            <span tabIndex="-1" name="displayCoupon" role="button" onKeyUp={this.displayCouponField} onClick={this.displayCouponField} style={{ color: this.props.textColor || 'white', cursor: 'pointer', textDecorationLine: 'underline' }}> Have a coupon? Click to apply it.</span>
          </div>
        }
        { (this.props.couponsEnabled && this.props.couponFieldVisible)
          && <div>
            {!this.props.couponValid
              && <form
                className={this.props.styles.couponForm}
                style={{ border: `2px solid ${this.props.couponSubmitted ? 'red' : 'white'}` }}
              >
                <input
                  id="couponField"
                  className={this.props.styles.couponInputField}
                  type="text"
                  placeholder="enter coupon..."
                  defaultValue={this.props.coupon}
                  onKeyPress={this.handleKeyPress}
                  onFocus={(e) => { e.target.placeholder = '' } }
                  onBlur={(e) => { e.target.placeholder = 'enter coupon...' } }
                />
                <button
                  className={this.props.styles.couponButton}
                  name="couponButton"
                  style={{ borderColor: this.props.buttonColor, background: this.props.buttonColor }}
                  onClick={this.handleCouponClick}
                >
                  { !this.props.couponValidating && <span>apply</span> }
                  { this.props.couponValidating && <Spinner color={'#FFFFFF'} size={30} /> }
                </button>
              </form>
            }

            <div className="couponMessage" style={{ marginBottom: '10px', fontSize: '12px', color: this.props.textColor }}>
              {(this.props.couponValid && this.props.couponSubmitted)
                && <p name="couponSuccess">Coupon successfully applied!</p>
              }
              {(!this.props.couponValid && this.props.couponSubmitted)
                && <p name="couponInvalid">Invalid coupon. Please try again.</p>
              }
            </div>
          </div>
        }

        </div>

    );
  }
}

export default injectStripe(CheckoutForm);
