import numbro from 'numbro'

import React from 'react'
import ReactDOM from 'react-dom'
import {
  createStore, combineReducers, applyMiddleware, compose,
} from 'redux'
import thunk from 'redux-thunk'

import { composeWithDevTools } from 'redux-devtools-extension';

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import config from 'config'
import * as reducers from './reducers'
import StripeForm from '../components/StripeForm'
import StripeFormElements from '../components/StripeFormElements'

import EmailForm from '../components/EmailForm'
import AboutMediaEmbed from '../components/AboutMediaEmbed'

const BugsnagSettings = config.bugsnagSettings;
BugsnagSettings.releaseStage = config.environment;
BugsnagSettings.appType = 'client'
BugsnagSettings.appVersion = config.version

BugsnagSettings.plugins = [new BugsnagPluginReact()]
Bugsnag.start(BugsnagSettings)
console.log(`launchpass v${config.version} client ${config.environment}`);

const setInitialState = (state) => {
  console.log('initialState', state);
  if (state.selector) {
    if (state.selector.price) {
      let price = state.selector.price.toString()
      price = price.indexOf('$') === 0 ? price : numbro(price).divide(100).formatCurrency('$0,0.00')
      state.selector.price = price
      state.selector.currentPrice = price
    }
  }

  // TODO add polyfill for document.location.search and URLSearchParams
  const params = new URLSearchParams(document.location.search);
  const type = params.get('s');
  const sessionId = params.get('session_id');
  if (type == '1' && sessionId) {
    state.form.subscribing = true;
    state.form.session_id = sessionId;
  }
  state.stripe = null;
  return state
}

const store = window.store = createStore(
  combineReducers(reducers),
  setInitialState(initialState),
  composeWithDevTools(
    applyMiddleware(thunk),
  ),
)

const renderStripeForm = () => {
  const state = store.getState()
  const embedmatch = '/embed';
  const match = '/v2';

  const embedmatches = document.location.pathname.substr(-embedmatch.length, embedmatch.length) === embedmatch;
  const v2matches = (document.location.pathname.substr(-match.length, match.length) === match)
  // if use elements if path is embed or v2
  if (embedmatches || v2matches) {
    if (state.page.period === 'free') {
      ReactDOM.render(
        <EmailForm
          errors={state.form.errors}
          openModal={state.openModal}
          styles={styles.EmailForm}
          modalStyles={styles}
          group={state.page.group}
          subscribing={state.form.subscribing}
          backgroundColor={state.page.backgroundColor}
          buttonColor={state.page.buttonColor}
          textColor={state.page.textColor}
          email={state.params.email}
          coupon={state.params.coupon}
        />,
        document.getElementById('free-form'),
      )
      if (state.page.mediaEmbed) {
        ReactDOM.render(
          <AboutMediaEmbed
            url={state.page.mediaEmbed}
          />,
          document.getElementById('media-embed'),
        )
      }
    } else {
      ReactDOM.render(
        <StripeFormElements
          url={state.page.url}
          channel={state.page.channel}
          group={state.page.group}
          price={state.selector.price}
          priceYearly={state.selector.priceYearly}
          yearlySelected={state.selector.yearlySelected}
          currentPrice={state.selector.currentPrice}
          errors={state.form.errors}
          subscribing={state.form.subscribing}
          period={state.page.period}
          trialPeriod={state.page.trialPeriod}
          openModal={state.openModal}
          styles={styles.StripeForm}
          modalStyles={styles}
          backgroundColor={state.page.backgroundColor}
          buttonColor={state.page.buttonColor}
          textColor={state.page.textColor}
          status={state.page.status}
          alternatePayment={state.page.alternatePayment}
          couponsEnabled={state.coupon.couponsEnabled}
          couponFieldVisible={state.coupon.couponFieldVisible}
          couponValid={state.coupon.valid} // vs couponValid
          couponId={state.coupon.id}
          couponAmountOff={state.coupon.amount_off}
          couponPercentOff={state.coupon.percent_off}
          couponDurationInMonths={state.coupon.duration_in_months}
          couponDuration={state.coupon.duration}
          couponSubmitted={state.coupon.couponSubmitted}
          couponValidating={state.coupon.couponValidating}
          email={state.params.email}
          coupon={state.params.coupon}
          stripe={state.stripe}
        />,
        document.getElementById('stripe-form'),
      )
      if (!embedmatches) {
        if (state.page.mediaEmbed) {
          ReactDOM.render(
            <AboutMediaEmbed
              url={state.page.mediaEmbed}
            />,
            document.getElementById('media-embed'),
          )
        }
      }
    }
  } else if (state.page.period === 'free') {
    ReactDOM.render(
      <EmailForm
        errors={state.form.errors}
        openModal={state.openModal}
        styles={styles.EmailForm}
        modalStyles={styles}
        group={state.page.group}
        subscribing={state.form.subscribing}
        backgroundColor={state.page.backgroundColor}
        buttonColor={state.page.buttonColor}
        textColor={state.page.textColor}
        email={state.params.email}
        coupon={state.params.coupon}
      />,
      document.getElementById('free-form'),
    )
    if (state.page.mediaEmbed) {
      ReactDOM.render(
        <AboutMediaEmbed
          url={state.page.mediaEmbed}

        />,
        document.getElementById('media-embed'),
      )
    }
  } else {
    ReactDOM.render(
      <StripeForm
        url={state.page.url}
        channel={state.page.channel}
        group={state.page.group}
        price={state.selector.price}
        priceYearly={state.selector.priceYearly}
        yearlySelected={state.selector.yearlySelected}
        currentPrice={state.selector.currentPrice}
        errors={state.form.errors}
        subscribing={state.form.subscribing}
        period={state.page.period}
        trialPeriod={state.page.trialPeriod}
        openModal={state.openModal}
        styles={styles.StripeForm}
        modalStyles={styles}
        backgroundColor={state.page.backgroundColor}
        buttonColor={state.page.buttonColor}
        textColor={state.page.textColor}
        status={state.page.status}
        alternatePayment={state.page.alternatePayment}
        couponsEnabled={state.coupon.couponsEnabled}
        couponFieldVisible={state.coupon.couponFieldVisible}
        couponValid={state.coupon.valid} // vs couponValid
        couponId={state.coupon.id}
        couponAmountOff={state.coupon.amount_off}
        couponPercentOff={state.coupon.percent_off}
        couponDurationInMonths={state.coupon.duration_in_months}
        couponDuration={state.coupon.duration}
        couponSubmitted={state.coupon.couponSubmitted}
        couponValidating={state.coupon.couponValidating}
        email={state.params.email}
        coupon={state.params.coupon}
      />,
      document.getElementById('stripe-form'),
    )
    if (state.page.mediaEmbed) {
      ReactDOM.render(
        <AboutMediaEmbed
          url={state.page.mediaEmbed}
        />,
        document.getElementById('media-embed'),
      )
    }
  }
}

store.subscribe(renderStripeForm)
renderStripeForm()
